import { /* chakra,*/ Badge, Heading, Text, VStack } from "@chakra-ui/react";
import * as React from "react";

import { APP_NAME } from "@/api/environment";
import { GLink } from "@/components/GLink";
// import { useLottie } from "@/components/lottie/useLottie";
// import { backgroundLottie } from "@/components/lottie/backgroundLottie";
import { AuthLayout } from "@/components/layouts/AuthLayout";
import { BaseLayoutProps } from "./BaseLayout";

type HomeLayoutProps = BaseLayoutProps & {
  icon?: React.ReactNode;
  linkTo?: { label: string; path: string };

  title?: string;
  description?: string;
  content?: React.ReactNode;

  children: React.ReactNode;
};

export function HomeLayout({
  icon,
  linkTo,

  title,
  description,
  content,

  children,

  ...layoutProps
}: HomeLayoutProps) {
  // TODO: Replace lottie images
  // const { ref: lottieRef } = useLottie({
  //   animationData: backgroundLottie,
  //   renderer: "svg",
  //   loop: true,
  //   autoplay: true,
  // });

  return (
    <AuthLayout
      {...layoutProps}
      mainProps={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      {/* <chakra.span
        ref={lottieRef}
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        zIndex="-1"
      /> */}

      <Badge
        display="block"
        bg="transparent"
        fontSize="xl"
        mx="auto"
        textAlign="center"
      >
        {APP_NAME}
      </Badge>

      <VStack textAlign="center" align="center" justify="space-between">
        {title || description || icon || content ? (
          <VStack
            as="header"
            maxWidth="sm"
            bg="blackAlpha.300"
            p="12"
            backdropBlur={10}
            rounded="lg"
            mx="auto"
          >
            {title ? (
              <Heading as="h1" textTransform="uppercase">
                {title}
              </Heading>
            ) : null}

            {icon}

            {description ? <Text>{description}</Text> : null}

            {content}
          </VStack>
        ) : null}
      </VStack>

      {children}

      {linkTo ? (
        <GLink href={linkTo.path} textAlign="center">
          Go to{" "}
          <Text as="span" textTransform="uppercase" fontWeight="semibold">
            {linkTo.label}
          </Text>
        </GLink>
      ) : null}
    </AuthLayout>
  );
}
