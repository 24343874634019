import { Button, Text } from "@chakra-ui/react";
import * as React from "react";

import { GLink } from "@/components/GLink";
import { SoloGazeIcon } from "@/components/icons/SoloGazeIcon";
import { HomeLayout } from "@/components/layouts/HomeLayout";
import { RouteMap } from "@/features/RouteMap";

export default function SoloHomePage() {
  return (
    <Button
      as={GLink}
      href={RouteMap.gazeCheckin.path}
      variant="primary"
      maxWidth="xs"
      mx="auto"
      px="12"
    >
      Begin&nbsp;
      <Text as="span" textTransform="uppercase">
        {" "}
        Solo-Gaze
      </Text>
    </Button>
  );
}

SoloHomePage.getLayout = (page: React.ReactNode) => (
  <HomeLayout
    key="solo"
    title="Solo-Gaze"
    description="Support connection with yourself."
    icon={<SoloGazeIcon height="2xs" width="2xs" />}
    // linkTo={{ label: "mutual-gaze", path: RouteMap.home.path("mutual") }}
  >
    {page}
  </HomeLayout>
);
