import * as React from "react";

import { RouteMap } from "@/features/RouteMap";
import { useRouter } from "next/router";
import SoloHomePage from "./home/solo";

export default function HomePage() {
  const router = useRouter();

  React.useLayoutEffect(() => {
    const prefetchRoutes = [
      RouteMap.home.path("solo"),
      RouteMap.home.path("mutual"),
      RouteMap.gazeCheckin.path,
      RouteMap.gazeCheckout.path,
      RouteMap.gazeSolo.path,
      RouteMap.gazeMutual.path,
    ];

    prefetchRoutes.forEach((route) => router.prefetch(route));
  }, [router]);

  return <SoloHomePage />;
}

HomePage.getLayout = SoloHomePage.getLayout;
